import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 注册ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
// 注册ElementPlus-icons
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 注册自定义指令（eg:按钮权限）
import registerDirectives from '@/directive/index';

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

const app = createApp(App)
// 循环注册icon
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// // 注册自定义指令（eg:按钮权限）
registerDirectives(app)

// app.directive('hasPerm',(el, binding) =>{
//   const { value } = binding;
  
//   let hasPerm = false;
//   hasPerm = checkPermiss(value)
//   if (!hasPerm) {
//     console.log('directive.param=', binding)
//     el.parentNode && el.parentNode.removeChild(el);
//   }
// })
// 将分页控件汉化
app.use(ElementPlus, {locale: zhCn})
app.use(store).use(router).use(ElementPlus).mount('#app')
