export default {
  namespaced: true,
  state: {
    manageSelectPath: localStorage.getItem("manageSelectPath") || '/users',
    memberSelectPath: localStorage.getItem("memberSelectPath") || '/users',
    dynamicPermission: localStorage.getItem("dynamicPermission") || [],
  },
  mutations: {
    setManageSelectPath(state, path) {
      state.manageSelectPath = path;
    },
    setMemberSelectPath(state, path) {
      state.memberSelectPath = path;
    },
    setDynamicPermission(state, path) {
      state.dynamicPermission = path
    },
  },
};
