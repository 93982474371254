<template>
  <router-view/>
</template>

<style lang="less">
body,html{
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.flex{
    display: flex;
    align-items: center;
}
.flex-float{
    display: flex;
    align-items: center;
}
.common-header{
    display: flex;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
