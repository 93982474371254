import { useStore } from 'vuex';

export default function registerHasPerm(app) {
  const store = useStore()

  app.directive('hasPerm',(el, binding) =>{
    const { value } = binding;
    
    const btnPermTree = JSON.parse(localStorage.getItem("userBtnPermission")) || []
    const requiredPermList = value instanceof Array ? value : [value];
    let hasPerm = false
    hasPerm = btnPermTree.some((btnPerm) => {
      return requiredPermList.includes(btnPerm);
    });
    if (!hasPerm) {
      el.parentNode && el.parentNode.removeChild(el);
    }
       
  })
}