<template>
    <div class="login-container">
        <div class="login-box">
            <h2>{{ pageTitle }}</h2>
            <form el-form ref="formRef" :model="loginFormData" @keydown.enter.native="keyDown()">
                <div class="form-group">
                    <el-input v-model="loginFormData.userName" placeholder="请输入用户名" />
                </div>
                <div class="form-group">
                    <el-input type="password" v-model="loginFormData.password" placeholder="请输入密码"/>
                </div>
                <div class="form-group" style="display: flex;justify-content: space-between;">
                    <el-input style="width: 200px;" v-model="loginFormData.verifyCode" placeholder="请输入验证码"></el-input>
                    <img :src="verifyCodeImg.img" alt="点击刷新" @click="funVerifyCode" style="width: 95px; height: 30px; cursor: pointer;">
                </div>
                <el-button type="primary" @click="btnLogin">登录</el-button>
            </form>
        </div>
    </div>
</template>
<script>
import '../../assets/static/css/admin/login.css';
import { reactive, toRefs, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { login, getVerifyCode } from '@/util/login'

export default {
  name: 'login',
  components: {
  },
  setup() {
  const envPageTitle = process.env.VUE_APP_GLOBAL_TITLE;
    const store = useStore()
    const router = useRouter()
    const data = reactive({
      pageTitle: envPageTitle,
      queryParams:{},
      loginFormData: {
        userName: '',
        password: '',
        uuid:'',
        verifyCode:''
      },
      verifyCodeImg: {
        uuid:'',
        img: ''
      }
    })
    const keyDown = (e)=>{
      btnLogin()
    }
    const funVerifyCode = () => {
      getVerifyCode().then(res => {
        // console.log('funVerifyCode', res)
        if(res.code === 200 && res.data !== null){
          data.loginFormData.uuid = res.data.uuid
          data.verifyCodeImg.uuid = res.data.uuid
          data.verifyCodeImg.img = res.data.img
        }
      })
    }
    const btnLogin=()=>{
      if(data.loginFormData.userName == ''
        || data.loginFormData.password == ''
        || data.loginFormData.verifyCode == ''){
        ElMessage.error('请输入登录信息！')
        return
      }
      login(data.loginFormData).then(res => {
        // console.log('btnLogin', res)
        if(res.code === 200 && res.data !== null){
          store.commit('userInfo/setCurrentUserInfo', res.data.loginUserVo)
          localStorage.setItem('loginFormData', JSON.stringify(res.data.loginUserVo))
          localStorage.setItem('tokenInfo', JSON.stringify(res.data.tokenInfo))
          router.push({
            path: '/layout'
          })
        } else if(res.code === 50033){
          ElMessage.error('验证码输入错误！')
          data.loginFormData.verifyCode = ''
          data.loginFormData.uuid = ''
          funVerifyCode()
        } else {
          ElMessage.error(res.msg)
          data.loginFormData.userName = ''
          data.loginFormData.password = ''
          data.loginFormData.verifyCode = ''
          data.loginFormData.uuid = ''
          funVerifyCode()
        }
      })
    }

    const clearlogout = () => {
      localStorage.removeItem("manageSelectPath");
      localStorage.removeItem("loginFormData");
      localStorage.removeItem("tokenInfo");
      store.commit('userInfo/setCurrentUserInfo', {});
      store.commit('menu/setManageSelectPath', "")
    }
    /** 页面默认加载执行 */
    clearlogout()
    funVerifyCode()

    return {
      btnLogin,
      keyDown,
      clearlogout,
      funVerifyCode,
      ...toRefs(data)
    }
  }

}
</script>
<style scoped lang="less">
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #89A8D7;
}

.login-box {
  width: 100%;
  max-width: 300px;
  padding: 20px;
  background-color: #2D8AC3;
  border-radius: 5px;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.75);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Makes sure the padding does not affect the total width of the input */
}

.form-group input[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #5cb85c;
  color: white;
  cursor: pointer;
}

.form-group input[type="submit"]:hover {
  background-color: #4cae4c;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}
</style>