import axios from "axios";
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import  router  from './../router'
const baseUrl = process.env.VUE_APP_BASE_URL;

let loadingObj = null
// 创建axios实例
const axiosService = axios.create({
  timeout: 6000,
  baseURL: baseUrl
});

//请求拦截-增加loading时异常的统一处理
axiosService.interceptors.request.use(config=>{
  loadingObj = ElLoading.service({
      lock: true,
      text: '正在努力加载中...',
      background: 'rgba(0, 0, 0, 0.7)',
  })
  const satokenInfo = JSON.parse(localStorage.getItem("tokenInfo"))
  //console.log("response::satokenInfo=", satokenInfo)
  if(satokenInfo != null) {
    let token = satokenInfo.tokenValue
    let tokenName = satokenInfo.tokenName
    //console.log("response::headers=" + token + ',tokenName=' + tokenName)
    config.headers[tokenName] = token
  }
  
  config.headers['Content-Type'] = "application/json;charset=utf-8"
  return config;
})
//响应拦截-对返回结果进行统一处理
axiosService.interceptors.response.use(response=>{
    loadingObj.close()
    // console.log("response::success", response.data)
    const data = response.data
    if(data.code == 50023 || data.code == 50022){
      ElMessage({
        message: data.msg,
        type: 'error',
      })
      localStorage.removeItem("manageSelectPath");
      localStorage.removeItem("loginFormData");
      localStorage.removeItem("tokenInfo");
      router.push({
        path: '/login'
      })
    } 
    return response.data;
}, error=>{
    loadingObj.close()
    // console.log("response::error", error)
    if (error.code === 'ERR_NETWORK') {
      ElMessage({
        message: '服务器错误,请稍后重试',
        type: 'error',
      })
      let jsonData = {}
      jsonData.code = 300
      jsonData.data =[]
      jsonData.msg ='服务器错误,请稍后重试'
      return jsonData
    }
    ElMessage({
        message: '服务器错误,请稍后重试',
        type: 'error',
    })
    return Promise.reject(error.response)
})

export const post =config=>{
    return axiosService({
        ...config,
        method:"post",
        data:config.data
    })
}

export const get =config=>{
    return axiosService({
        ...config,
        method:"get",
        params: config.data
    })
}