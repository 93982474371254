import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/admin/login.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    redirect: 'login',
  },{
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    component: () => import('@/views/admin/login.vue'),
  },{
    path: '/layout',
    name: 'layout',
    redirect: 'users',
    component: () => import('@/views/admin/Layout.vue'),
    children:[{
      path: '/users',
      name: 'users',
      meta: { title: '用户管理' },
      component: () => import('@/views/admin/user/list.vue'),
    },{
      path: '/news',
      name: 'news',
      meta: { title: '公告管理' },
      component: () => import('@/views/admin/news/list.vue'),
    },{
      path: '/question',
      name: 'question',
      meta: { title: '投诉管理' },
      component: () => import('@/views/admin/question/list.vue'),
    },{
      path: '/product',
      name: 'product',
      meta: { title: '服务管理' },
      component: () => import('@/views/admin/product/list.vue'),
    },{
      path: '/order',
      name: 'order',
      meta: { title: '订单管理' },
      component: () => import('@/views/admin/order/list.vue'),
    },{
      path: '/orderAnalysis',
      name: 'orderAnalysis',
      meta: { title: '订单数据分析' },
      component: () => import('@/views/admin/dataAnalysis/orderAmount.vue'),
    }]
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('@/views/error/Forbidden.vue')
  },
  {
    path: '/notFound',
    name: 'notFound',
    component: () => import('@/views/error/NotFound.vue')
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

/** 路由守卫，当每个页面没有登录信息时，则全部跳转到首页main
 * to: 从哪个页面来
 * from:到哪个页面去
 * next:执行next后才会进行页面跳转
*/
router.beforeEach((to, from, next)=>{
   //console.log('from', from)
   //console.log('to',to)
  // 判断用户是否登录  
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }

  const currentUserInfo = store.state.userInfo.currentUserInfo || null
  const satokenInfo = JSON.parse(localStorage.getItem("tokenInfo"))

  if(satokenInfo === null || currentUserInfo === null){
    //console.log('未登录')
    //未登录
    if(to.path === '/'){
      next('/login')
      return
    }
    if(to.path === '/login'){
      next()
      return
    }
    next('/login')
  } else if(currentUserInfo.nickName != undefined && currentUserInfo.nickName != null) {
    let dyPaths = []
    if(currentUserInfo.manageType === '1'){
      dyPaths.push('/orderAnalysis')
    }
    // 已登录
    //console.log('已登录')
    if(to.path === '/login'
        || to.path === '/users'
        || to.path === '/news'
        || to.path === '/question'
        || to.path === '/product'
        || to.path === '/order'
        || dyPaths.includes(to.path)
        || to.path === '/layout') {
      next()
    } else {
      next('/login')
    }
  } else {
    next('/notFound')
  }
})

export default router
