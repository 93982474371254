import { createStore } from 'vuex'
import userInfo from './state/userinfo.state.js'
import menu from './state/menu.state.js'

export default createStore({
  //数据模块比较多，可以分模块存储
  modules: {
    userInfo,
    menu
  }
})
