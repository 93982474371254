export default {
  namespaced: true,
  state: {
    currentUserInfo: (localStorage.getItem("loginFormData") && JSON.parse(localStorage.getItem("loginFormData"))) || {},
  },
  mutations: {
    setCurrentUserInfo(state, userInfo) {
      state.currentUserInfo = userInfo;
    },
  },
};
