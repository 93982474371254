import {get, post} from './manageService'

/**
 * 登录系统
 * @param {*} data 
 * @returns 
 */
export const login = (data) => {
  return post({
    url: "/user/login",
    data: data,
  });
}

/**
 * 登录系统
 * @param {*} data 
 * @returns 
 */
export const logout = (data) => {
  return post({
    url: "/user/logout",
    data: data,
  });
}

/**
 * 获取验证码
 * @returns base64验证码图片
 */
export const getVerifyCode = () => {
  return get({
    url: process.env.VUE_APP_BASE_URL + '/sys/captcha?' + new Date().getTime()
  })
}